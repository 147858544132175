import React from 'react';
import styled from 'styled-components';
import Download from '../../images/SectionFour/downloadicon.svg';
import Receipt from '../../images/SectionFour/receipt.svg';
import Control from '../../images/SectionFour/controle.svg';

const Container = styled.div`
  background-color: #fcfcfc;
  border-radius: 18px;
  display: flex;
  align-items: center;
  flex-flow: row nowrap;
  width: ${(props) => (props.width ? props.width : 'unset')};
  max-width: ${(props) => (props.maxWidth ? props.maxWidth : 'unset')};
  min-width: ${(props) => (props.minWidth ? props.maxWidth : 'unset')};
  justify-content: space-between;
  padding: 0.5rem 0.15rem 0.5rem 0.15rem;
  margin: 2rem 0.5rem;
  text-overflow: ellipsis;
  height: fit-content;
`;

const DocTitle = styled.h5`
  color: rgba(47, 137, 117, 1);
  font-size: 1.15rem;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow-x: hidden;
  max-width: 75%;
  @media (max-width: 500px) {
    max-width: unset;
    font-size: 1rem;
    white-space: unset;
    width: 85%;
  }
`;

const saveFile = (e, title, url, cate) => {
  e.preventDefault();
  if (!url) {
    url = `https://profeel-checkreno.s3.eu-west-1.amazonaws.com/public/Fiches/F-${cate}-${title}.pdf`;
  } else {
    url = `https://profeel-checkreno.s3.eu-west-1.amazonaws.com/public/Fiches/F-${cate}-${url}.pdf`;
  }
  saveAs(title, url);
};

const saveAs = (filename, url) => {
  const link = document.createElement('a');
  link.download = filename;
  link.href = url;
  link.target = '_blank';
  document.body.appendChild(link);
  link.click();
  window.setTimeout(() => {
    URL.revokeObjectURL(url);
    document.body.removeChild(link);
  }, 0);
};

const getIcon = (src) => (src === 'Reception' ? Receipt : Control);

const DocItem = (props) => {
  return (
    <Container width={props.width} min-width={props.minWidth} max-width={props.maxWidth}>
      <div className="flex flex-row items-center w-4/5 mx-3">
        <img src={getIcon(props.cate)} className="mr-3" alt="document-icon" />
        <div className=" flex flex-col w-full">
          <span style={{ color: 'rgba(170, 195, 198, 1)', fontSize: '0.75rem' }}>{props.cate}</span>
          <DocTitle>{props.title}</DocTitle>
        </div>
      </div>
      <button
        onClick={(e) => {
          saveFile(e, props.title, props.url, props.cate);
        }}
      >
        <img src={Download} className="DownLoadIcon / pr-3 cursor-pointer" alt="download icon" />
      </button>
    </Container>
  );
};

export default DocItem;
