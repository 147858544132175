import React from 'react';
import styled from 'styled-components';
import Aside from '../images/Aside.svg';

const gradientText = 'linear-gradient(320.07deg, #2F8975 24.52%, #0078A3 72.78%)';

const StyledTitle = styled.h3`
  background: ${gradientText};
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 1.25rem;
  font-weight: 800;
  @media (max-width: 600px) {
    font-size: 1.25rem;
  }
`;
const SectionTitle = (props) => (
  <div className="flex flex-row items-center justify-start">
    <img src={Aside} alt="Checkreno logo" className="h-16 mr-4" />
    <StyledTitle>{props.title} </StyledTitle>
  </div>
);

export default SectionTitle;
