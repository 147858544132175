import React, { useState } from 'react';

const InputText = ({ setInputValue, ...props }) => {
  const [isActive, setIsActive] = useState(false);

  const results = props?.results?.map((groups) => groups[1]);
  let titles = [];
  results.forEach((element) => {
    element.forEach((result) => titles.push(result.title));
  });
  // console.log(results, '>>>>>', titles, titles.length);

  const autocomplete =
    isActive && titles?.length > 0 && props?.inputValue.length > 0 && props?.results?.length > 0;

  const setResult = (result) => {
    // console.log('select');
    setInputValue(result);
    setIsActive(false);
  };

  const handleBlur = (e) => {
    // console.log('blur');
    e.stopPropagation();
    setIsActive(false);
  };

  return (
    <div className={`${autocomplete ? 'BorderGreen' : ''} InputSearch /  w-full -sm:w-80  flex flex-col`}>
      <input
        type="text"
        placeholder="ex: chantier rénovation"
        className={`${
          autocomplete ? 'BorderGreenBis' : 'AutocompleteInput'
        } "font-extralight  -sm:w-80 w-full h-12 py-3 -lg:px-4 px-2 text-green-700 bg-gray-100  rounded-2xl`}
        onChange={(e) => setInputValue(e.target.value)}
        onFocus={() => setIsActive(true)}
        onBlur={(e) => handleBlur(e)}
        value={props?.inputValue}
      />
      <span className="BottomLine / absolute"></span>
      {autocomplete && (
        <div
          className="Autocomplete / -sm:w-80 max-h-48 absolute flex flex-col overflow-y-scroll"
          style={{ background: '#f5f5f5' }}
        >
          {titles?.map((title, index) => (
            <>
              <span
                key={index}
                className="hover:text-green-700 rounded-2xl overflow-ellipsis hover:bg-white -lg:px-8 w-full px-6 py-2 pl-2 my-2 cursor-pointer"
                onMouseDown={() => setResult(title)}
              >
                {title}
              </span>
            </>
          ))}
        </div>
      )}
    </div>
  );
};
export default InputText;
