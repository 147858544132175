import React from 'react';

const blue = `rgba(119, 206, 255, 1)`;
const orange = `rgba(255, 156, 101, 1)`;

const Checkbox = ({ setReceiptFilter, setControlFilter }) => {
  return (
    <div className="sm:my-3 sm:w-full -sm:mr-9 sm:justify-center flex-nowrap flex flex-row justify-between">
      <div className="mr-8">
        <label className="container">
          <span className="text-sm font-bold" style={{ color: blue }}>
            Réception
          </span>
          <input
            id="recep"
            type="checkbox"
            value="Reception"
            onChange={(e) => (e.target.checked ? setReceiptFilter(true) : setReceiptFilter(false))}
          />
          <span className="checkmark" id="reception"></span>
        </label>
      </div>
      <div>
        <label className="container">
          <span className="text-sm font-bold" style={{ color: orange }}>
            Autocontrôle
          </span>
          <input
            id="auto"
            type="checkbox"
            value="Autocontrole"
            onChange={(e) => (e.target.checked ? setControlFilter(true) : setControlFilter(false))}
          />
          <span className="checkmark" id="autocontrole"></span>
        </label>
      </div>
    </div>
  );
};

export default Checkbox;
