import React from 'react';
import Select, { components } from 'react-select';
import DropdownIcon from '../../../images/vectors/selectArrow.svg';

const options = [
  { value: 'Isolation des murs', label: 'Isolation des murs' },
  { value: "Isolation d'un comble ou d'une toiture", label: "Isolation d'un comble ou d'une toiture" },
  { value: "Isolation d'un plancher bas", label: "Isolation d'un plancher bas" },
  { value: 'Menuiseries extérieures', label: 'Menuiseries extérieures' },
  { value: 'Ventilation mécanique', label: 'Ventilation mécanique' },
  { value: 'Chauffage par pompe à Chaleur (PAC)', label: 'Chauffage par pompe à chaleur (PAC)' },
  { value: 'Chauffage au bois', label: 'Chauffage au bois' },
  { value: 'Chauffage Gaz', label: 'Chauffage Gaz' },
  { value: 'Chauffage Solaire', label: 'Chauffage Solaire' },
  { value: 'Eau Chaude Sanitaire (ECS)', label: 'Eau Chaude Sanitaire (ECS)' },
];

const customStyles = {
  control: () => ({
    background: '#F5F5F5',
    display: 'flex',
    flexFlow: 'flex row',
    flexWrap: 'nowrap',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingLeft: '0.5rem',
  }),
  option: () => ({
    color: 'rgba(122, 122, 122, 1)',
    background: 'white',
    padding: '1rem 0.5rem',
    ':hover': { background: '#F5F5F5', color: '#2F8975', cursor: 'pointer' },
  }),
  placeholder: () => ({
    background: '#F5F5F5',
    height: '100%',
    color: 'rgba(47, 137, 117, 1)',
    padding: '1rem 0.5rem',
  }),
  dropdownIndicator: () => ({
    border: 'none',
  }),
  valueContainer: () => ({
    padding: '0rem',
    height: '100%',
  }),
  input: () => ({
    display: 'none',
  }),
  indicatorSeparator: () => ({
    border: 'none',
  }),
  menu: () => ({
    margin: '0px !important',
    top: '100%',
    zIndex: '1',
    position: 'absolute',
    width: '100%',
  }),
  menuList: () => ({
    paddingTop: '0px',
    maxHeight: '300px !important',
    overflowY: 'auto',
    position: 'relative',
    padding: '0',
  }),
};

const green = '#2F8975';

const { ValueContainer, Placeholder, Option, DropdownIndicator } = components;

const CustomValueContainer = ({ children, state, selectedGroups, ...props }) => {
  // console.log(props);
  return (
    <ValueContainer {...props}>
      <Placeholder {...props}>
        <span className="h-full">
          {props?.selectProps?.value?.length ? props?.selectProps?.value?.length : 'Aucun'} travaux
          sélectionné(s)
        </span>
      </Placeholder>
    </ValueContainer>
  );
};

const CustomOption = ({ children, state, ...props }) => {
  // console.log(props?.isSelected);
  return (
    <Option {...props}>
      <div className="flex flex-row items-center justify-between w-full">
        <p>{children}</p>

        <div className="boxContainer / flex">
          <input
            type="checkbox"
            style={{ color: green, display: 'none' }}
            className={`${props.isSelected ? 'selectedOption' : ''} selectOption`}
          />
          <div className="checkmarkSelect selectOption"></div>
        </div>
      </div>
    </Option>
  );
};

const CustomDropdownIndicator = ({ children, state, ...props }) => {
  // console.log(props);
  return (
    <DropdownIndicator {...props}>
      <div className="p-4">
        <img
          src={DropdownIcon}
          className={props?.selectProps?.menuIsOpen ? 'open' : 'close'}
          alt="arrow menu"
        />
      </div>
    </DropdownIndicator>
  );
};

const SelectPrestationMenu = ({ setSelectValue, props }) => {
  return (
    <div className="lg:w-full lg:mt-6 w-1/4">
      <Select
        selectedValues={props}
        components={{
          ValueContainer: (selectedValues) => <CustomValueContainer {...selectedValues} />,
          Option: CustomOption,
          DropdownIndicator: CustomDropdownIndicator,
        }}
        onChange={setSelectValue}
        options={options}
        isMulti={true}
        styles={customStyles}
        isSearchable={false}
        hideSelectedOptions={false}
        closeMenuOnSelect={false}
      />
    </div>
  );
};
export default SelectPrestationMenu;
