import React, { useState, useEffect } from 'react';
import { css } from '@emotion/react';
import ClipLoader from 'react-spinners/ClipLoader';
import DocItem from './DocItem';
import FilterForm from './FilterForm/FilterForm';
import Fuse from 'fuse.js';
import groupBy from 'lodash.groupby';
import { Fragment } from 'react';

const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;

const green = `#2F8975`;

const ReceptionDocs = require('../../DocReception.json');
const AutocontroleDoc = require('../../DocAutocontrole.json');
const docs = ReceptionDocs.concat(AutocontroleDoc);
const docsByGroup = Object.entries(groupBy(docs, (docs) => docs.travaux));

const MultipleRows = () => {
  const [loading, setLoading] = useState(false);
  const [filtersValues, setFiltersValues] = useState({});
  const [filteredResults, setFilteredResults] = useState([]);
  const [message, setMessage] = useState('');

  function checkFilters() {
    setLoading(true);
    const data = docs;
    let selectedValues = [];
    const allChecked = filtersValues.receiptFilter && filtersValues.controlFilter;
    console.log(filtersValues);
    //   FILTRES MENU SELECT
    if (filtersValues?.selectValue?.length > 0) {
      const filters = filtersValues.selectValue.map((filter) => filter.value);
      for (let i = 0; i < filters?.length; i++) {
        const oneFilterValues = data.filter((doc) => doc.travaux === filters[i]);
        selectedValues.push(...oneFilterValues);
      }
    }

    // FILTRES CHECKBOX
    if (!allChecked) {
      if (filtersValues.receiptFilter && selectedValues.length > 0) {
        selectedValues = selectedValues.filter((doc) => doc.cate === 'Reception');
      } else if (filtersValues.receiptFilter) {
        selectedValues = docs.filter((doc) => doc.cate === 'Reception');
      }
      if (filtersValues.controlFilter && selectedValues.length > 0) {
        selectedValues = selectedValues.filter((doc) => doc.cate === 'Autocontrole');
      } else if (filtersValues.controlFilter) {
        selectedValues = docs.filter((doc) => doc.cate === 'Autocontrole');
      }
    }

    //FILTRE TEXTE
    if (selectedValues.length > 0 && filtersValues.searchInputValue.length > 0) {
      const fuse = new Fuse(selectedValues, {
        keys: ['title'],
        threshold: 0.0,
        ignoreLocation: true,
      });
      selectedValues = fuse.search(filtersValues.searchInputValue).map((result) => result.item);
    } else if (filtersValues.searchInputValue?.length > 0) {
      const fuse = new Fuse(data, {
        keys: ['title'],
        threshold: 0.0,
        ignoreLocation: true,
      });
      selectedValues = fuse.search(filtersValues.searchInputValue).map((result) => result.item);
    }
    const filteredResults = [...selectedValues];
    const filteredResultsByGroup = Object.entries(
      groupBy(filteredResults, (filteredResults) => filteredResults.travaux)
    );

    setFilteredResults(filteredResultsByGroup);

    // SET MESSAGE
    const anyFilter =
      filtersValues.receiptFilter ||
      filtersValues.controlFilter ||
      filtersValues?.searchInputValue?.length > 0 ||
      filtersValues?.selectValue?.length > 0;

    if (anyFilter && filteredResults.length > 1) {
      setMessage(`${filteredResults.length} résultats trouvés`);
    } else if (anyFilter && filteredResults.length === 1) {
      setMessage(`1 résultat trouvé`);
    } else if (anyFilter && filteredResults.length === 0) {
      setMessage('Aucun résultat avec ces critères de recherche, veuillez saisir une nouvelle recherche.');
    } else {
      setMessage('');
    }
    // console.log(filtersValues.searchInputValue, '>>>', message);
    setLoading(false);
  }

  useEffect(() => {
    checkFilters();
  }, [filtersValues]);

  return (
    <>
      <FilterForm setFiltersValues={(values) => setFiltersValues(values)} results={filteredResults} />
      {loading && (
        <div className="md:h-full flex justify-center w-full">
          <ClipLoader color={green} loading={loading} css={override} size={150} />
        </div>
      )}
      {message && (
        <span className=" text-green-700" style={{ color: green }}>
          {message}
        </span>
      )}
      <div className="SlideWrapper / mt-9 flex flex-wrap content-start justify-between w-full">
        {filteredResults.length === 0 &&
          docsByGroup.map((group, index) => (
            <div className="mb-9 flex flex-row flex-wrap w-full">
              <div className="w-full">
                <h3 key={index} className="text-xl text-green-700">
                  {group[0]}
                </h3>
              </div>
              <Fragment>
                {group[1].map((doc, index) => (
                  <div key={index} className="sm:w-full flex justify-center w-1/2">
                    <DocItem cate={doc.cate} icon={doc.icon} title={doc.title} url={doc.url} width="97%" />
                  </div>
                ))}
              </Fragment>
            </div>
          ))}
        {filteredResults.length > 0 &&
          filteredResults.map((group, index) => (
            <div className="mb-9 flex flex-row flex-wrap w-full">
              <div className="w-full">
                <h3 key={index} className="text-xl text-green-700">
                  {group[0]}
                </h3>
              </div>
              <Fragment>
                {group[1].map((doc, index) => (
                  <div key={index} className="sm:w-full flex justify-center w-1/2">
                    <DocItem cate={doc.cate} icon={doc.icon} title={doc.title} url={doc.url} width="97%" />
                  </div>
                ))}
              </Fragment>
            </div>
          ))}
      </div>
    </>
  );
};

export default MultipleRows;
