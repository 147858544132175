import React from 'react';
import SectionTitle from '../sectionTitle';
import Carussel from './Carussel';

const Wrapper = () => (
  <div className="MainWrapper / -lg:px-16 flex flex-col justify-between h-full px-3 pt-12 pb-48">
    <SectionTitle title="Télécharger les fiches d'Autocontrôle et de Réception" />
    <div className="h-full">
      <div className="overflow-x-hidden">
        <Carussel />
      </div>
    </div>
  </div>
);

export default Wrapper;
