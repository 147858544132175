import React from 'react';
import { Link } from 'gatsby';
import Wrapper from '../components/Downloads/Wrapper';
import Layout from '../components/layout';
import ArrowText from '../images/ArrowText.svg';
import Circles from '../images/vectors/circles.svg';

const vectorIndex = {
  zIndex: '-1',
};

const DownloadPage = () => (
  <Layout>
    <img src={Circles} className="-right-24 w-96 xl:hidden -top-16 fixed" alt="vectors" style={vectorIndex} />
    <Link to="/">
      <div className="-lg:px-16 flex flex-row justify-start px-3 mt-40">
        <img src={ArrowText} alt="arrowvector" />
        <p className="ml-4 font-bold text-green-700">Retour</p>
      </div>
    </Link>
    <Wrapper />
  </Layout>
);

export default DownloadPage;
