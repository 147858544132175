import React, { useEffect, useState } from 'react';
import Checkbox from './Checkbox';
import SelectPrestationMenu from './SelectMultiple';
import InputText from './InputText';

const FilterForm = ({ setFiltersValues, results }) => {
  const [searchInputValue, setInputValue] = useState('');
  const [receiptFilter, setReceiptFilter] = useState(false);
  const [controlFilter, setControlFilter] = useState(false);
  const [selectValue, setSelectValue] = useState('');

  const formValues = { searchInputValue, receiptFilter, controlFilter, selectValue };

  useEffect(() => {
    setFiltersValues(formValues);
  }, [searchInputValue, receiptFilter, controlFilter, selectValue]);

  return (
    <div className="sm:my-3 flex flex-row flex-wrap items-center justify-between w-full mt-16 mb-12">
      <InputText
        setInputValue={(value) => setInputValue(value)}
        results={results}
        inputValue={searchInputValue}
      />
      <Checkbox
        setReceiptFilter={(value) => setReceiptFilter(value)}
        setControlFilter={(value) => setControlFilter(value)}
      />
      <SelectPrestationMenu setSelectValue={(value) => setSelectValue(value)} selectedGroups={selectValue} />
    </div>
  );
};

export default FilterForm;
